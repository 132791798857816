.landing-page {
    /* display: flex;
    flex-direction: column;*/
    /* align-items: center; */
    /* text-align: left;  */
    background-color: #f9f9f9;
    padding-top: 70px; /* enough so content won't hide behind the fixed header */
    padding-left: 20px;  
    padding-right: 20px;
  }
  
  /* Fixed header */
  .logo-header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 9999;
    padding: 10px 20px;
    background-color: #f9f9f9;
    border-bottom: 1px solid #ccc;
  }
  
  .logo-text {
    font-family: "Great Vibes", cursive;
    font-size: 2rem;
    font-weight: bold;
    margin: 0;
  }
  
  /* Container for two columns */
  .landing-container {
    display: flex;         /* side-by-side columns */
    flex-direction: row;     
    justify-content: space-between;
    /* gap: 1rem; */
    /* padding: 2rem 0;   */
    /* align-items: flex-start; */
    align-items: stretch;
    padding: 0 20px;  
    max-width: 1200px;
    margin: 0 auto;        /* center in the page */
    margin-top: 20px;
    box-sizing: border-box; /* So padding doesn't push total width beyond 100% */
    flex-wrap: wrap; 
    width: 100%;            /* or 80%, or a fixed width like 1200px */    
  }
  
  /* Left column */
  .left-column {
    flex: 1;
    display: flex;
    flex-direction: column;/************************************************/
    /* gap: 1rem; */
    /* If you want the text left-aligned within this column, do: */
    text-align: left !important;
    padding: 20px;
  }
  
  /* Right column */
  .right-column {
    flex: 1;
    display: flex;
    justify-content: center;
    /* align-items: center; */
    padding: 20px;
  }

  .left-column,
  .right-column {
  flex: 1 1 50%;
  min-width: 300px; /* or something suitable */
  box-sizing: border-box;
}

  .right-column img {
    max-width: 100%;
    height: auto;
    border-radius: 8px;       /* optional style */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* optional style */
  }

  .left-column h1 {
    font-size: 4rem; /*2.478*/
    font-weight: bold;
    color: #333;
    margin-top: 0;    /* Remove default top margin */
    margin-bottom: 20px;
    /* font-family: "Montserrat", sans-serif; */
    /* font-family: Arial, Helvetica, sans-serif;  */
  }
  
  .left-column p {
    font-size: 1.532rem; /*1-1.125*/
    color: #444;
    margin: 10px 0 20px;
    /* text-align: left !important; */
  }

  .right-column {
    padding: 20px;
    box-sizing: border-box;
  }

  .benefits-row {
    display: flex;
    justify-content: center; /* center them horizontally */
    align-items: center;      /* center them vertically in the row */
    gap: 3rem;               /* space between the items */
    margin-top: 2rem;        /* a bit of space above the row */
    /* margin-left: 11rem; */
    text-align: center;      /* center text inside each item if you like */
  }
  
  .benefit-item {
    font-weight: bold;
    font-size: 1.1rem;
    color: #333;
    /* You could also add padding, margin, or a nice icon here if needed */
  }

  .action-button {
    width: 100%;
    display: flex;
    /* justify-content: center; */
    justify-content: flex-start; /* Aligns the button to the left */
    margin-top: 20px;
  }

  .benefits-list {
    list-style: none;         
    margin: 1rem 0 0;        
    padding: 0;

    display: flex;          
    /* display: block;   */
    flex-direction: column;   
    /* align-items: left;       */
    /* align-items: flex-start; */
    justify-content: flex-start; 
    gap: 0.5rem;             
    /* text-align: left;  */
    /* list-style: none;  */
  }
  
  /* Each list item styling */
  .benefits-list li {
    font-weight: bold;
    font-size: 1.1rem; /* or any size you prefer */
    color: #333;
    display: flex;     /* so icon & text can align nicely */
    /* align-items: center; */
    /* text-align: left !important; */
  }

  /* The check icon (if using text or unicode ✔) */
  .check-icon {
    margin-right: 8px; /* space between icon and text */
    color: #000;       /* or your brand color */
    font-weight: bold; 
  }

  .action-button {
    width: 100%;
    display: flex;
    /* justify-content: center; */
    justify-content: flex-start; /* Aligns the button to the left */
    margin-top: 20px;
  }

  .landing-button {
    padding: 12px 24px;
    font-size: 1rem;
    background-color: #000;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
  }
  
  .landing-button .arrow {
    margin-left: 8px; /* space between text and arrow */
  }
  
  .landing-button:hover {
    background-color: #d9d9d9; /* Light gray background on hover */
    border-color: #ccc; /* Darker border */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Deeper shadow on hover */
    transform: translateY(-2px); /* Slight lift effect */
  }
  
  

.how-it-works-container {
    text-align: center;
    /* width: 100%;
    margin-top: 80px;
    margin-left: 120px;
    margin-right: 120px; */
    max-width: 1200px;
  margin: 100px auto 0 auto; /* center with some top margin */
  /* or margin-top: 80px; margin-bottom: 0; margin-left: auto; margin-right: auto; */
  padding: 0 20px;          /* same side padding as .landing-container */
  text-align: center;
  }
  
  /* Align steps in a row */
  .how-it-works-box {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; /* Allow wrapping on smaller screens */
    justify-content: space-between; /* Distribute items evenly */
    align-items: stretch; /* Ensure all steps have the same height */
    gap: 15px; /* Add spacing between items */
    margin-bottom: 40px;
    /* margin-left: 120px;
    margin-right: 120px; */
  }
  
  /* Step Styling */
  .step {
    display: flex;
    flex-direction: column;
    /* justify-content: space-between;  */
    align-items: center; /* Center items horizontally */

    background-color: transparent;
    box-shadow: none;
    border-radius: 0;
    border: none;

    padding: 20px;
    /* Remove min-height if you don't need it */
    min-height: auto;

    flex: 1 1 280px; 
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);  */
    /* background-color: white;  */
    /* border-radius: 8px;  */
    /* min-height: 500px;  */
    /* box-sizing: border-box;  */
  }
  
  .step-number {
    width: 30px;
    height: 30px;
    border: 2px solid #333;      /* or your brand color */
    border-radius: 50%;         /* make it a circle */
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 1.2rem;
    margin-bottom: 10px;
  }
  
  .step-title {
    font-size: 1.2rem;          
    font-weight: 600;           
    margin: 0;
  }

  .step-desc {
    font-size: 1rem;
    color: #555;
    max-width: 350px;           
    margin: 0 0 20px 0;
    text-align: center;
  }

  .rect-bg {
    width: 300px;              
    height: 300px;
    background-color: #ddd; 
    margin: 0 auto 10px;        
    border-radius: 8px;       
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;           
    margin-bottom: 10px;       
    padding: 20px 10px; /* Reduce horizontal padding to maximize space for color image */
  }

  .how-it-works-container h1 {
    font-size: 2.478rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 20px;
  }
  
  /* .step h2{
    font-size: 1.532rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
  } */
  
  /* Image Container to Center Images Vertically */
  .image-container {
    flex-grow: 1; /* Take up available space */
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 10px 0;
    flex-direction: column;  /* Stack vertically */
  }
  
  /* Special container for step 2 to handle color options properly */
  .step2 .image-container {
    flex-direction: column;
    position: relative;
    width: 100%;
    overflow-x: hidden; /* Prevent horizontal scrolling if image is too wide */
    align-items: center;
    padding: 0;
  }

  .step2 .step-color {
    width: 100%;
    max-width: 500px; /* Increased from 300px to show more colors */
    height: auto;
    object-fit: contain;
    transform: none; /* Remove transform to avoid scaling issues */
    padding-bottom: 10px;
    margin: 0 auto;
  }

  .step2 .step-image {
    width: 100%;
    max-width: 280px;
    height: auto;
    object-fit: contain;
    transform: scale(1.1);
    padding-top: 10px;
    padding-bottom: 20px;
    margin: 0 auto;
  }

  /* Media query adjustments for mobile */
  @media (max-width: 768px) {
    .step2 .step-color {
      max-width: 400px; /* Increased from 250px */
    }
    
    .step2 .step-image {
      max-width: 230px;
    }
  }

  @media (max-width: 480px) {
    .step2 .step-color {
      max-width: 320px; /* Increased from 200px */
    }
    
    .step2 .step-image {
      max-width: 180px;
    }
  }

.text-box {
    background-color: #fff;            
    border: 1px solid #bbb;           /* light gray border */
    border-radius: 4px;               /* slightly rounded corners */
    padding: 4px 4px;                /* spacing around the text */
    margin: 4px auto;                 /* optional: some spacing around the box */
    font-family: "Courier New", monospace;  /* monospaced font to match screenshot */
    font-size: 0.9rem;                /* adjust as desired */
    line-height: 1.4;                 /* comfortable line spacing */
    color: #333;                      /* text color */
    max-width: 250px;
    margin-top: 20px; 
    /* optionally add a subtle box shadow or inset styling if you like */
  }
  


  /* Step Images */
  .step-image {
    width: 100%;
    max-width: 200px;
    /* height: auto; */
    object-fit: contain; 
    /* border-radius: 8px; */
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
    border: none;
  box-shadow: none; /* remove the shadow */
  border-radius: 0; /* if you also want to remove rounded corners */
  }


  .step1 .step-image {
    transform: scale(1); 
    /* border: 2px solid #333;   */
    border-radius: 4px;      /* optional: rounded corners */
    margin-bottom: 15px;
}

  .step2 .step-color {
    transform: scale(1);        /* Slightly smaller */
    /* or: width: 90%;            /* Another approach if you want a direct width */
    /* or: margin-top: 10px;      /* if you need to nudge it down */
    padding-bottom: 10px;
}

  .step2 .step-image {
    transform: scale(1.3);        /* Slightly smaller */
    /* or: width: 90%;            /* Another approach if you want a direct width */
    /* or: margin-top: 10px;      /* if you need to nudge it down */
    padding-top: 20px;
    padding-bottom: 20px;
}
  
  .step3 .step-image {
    max-width: 250px; 
    transform: scale(1.2); 
    transition: transform 0.3s ease; 
  }
  
  /* .step h3 {
    font-size: 1.2rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
  } */
  
  .step p {
    font-size: 1.2rem;
    color: #333;
    margin-top: 20px; /* Space above the text */
  }

     
  .text-box p {
    font-size: 1rem;
    color: #333;
    /* margin: 10px 0; */
    margin-top: 20px;
  }
  
  
  /* Responsive Adjustments */
  @media (max-width: 768px) {
    .landing-container {
      flex-direction: column;    /* stack columns vertically */
      /* align-items: flex-start;   */
      align-items: center;  
    }
  
    .left-column {
      text-align: center !important;;     /* override the desktop left-align */
    }

    .left-column h1 {
      font-size: 2.4893rem; /* or 2rem, depending on your preference */
    }
  
    .left-column p {
      font-size: 1.53rem; /*1-1.125*/
      color: #444;
      margin: 10px 0 10px;
    }
  
    .left-column, .right-column {
      width: 90%;             /* shrink to 90% so there's some left/right padding */
      display: flex;
      flex-direction: column;
      align-items: center;    /* center the content within each column */
      margin-bottom: 20px;    /* space between columns */
    }
    .action-button {
      justify-content: center;
    }
  
    .benefits-list {
      flex-direction: column;
      justify-content: center;
    }

    .benefits-row {
        flex-direction: column;
        gap: 1rem; /* optionally reduce spacing */
      }
  
    .how-it-works-box {
      flex-direction: column;
      align-items: center;
    }
  
    .step {
      width: 90%;
      min-height: auto; /* Allow height to adjust based on content */
    }

    .rect-bg {
        width: 100%;      /* or 90%, or anything that fits within your layout */
        margin: 0 auto;  /* keep it centered */
      }
  }
  