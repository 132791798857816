/* CategoryTabs.css - Specific styles for category tabs */
.category-tabs-styled {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  width: 100%;
}

.category-tabs-styled .main-tabs {
  display: flex;
  border-radius: 40px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border: 1px solid #e0e0e0;
  width: fit-content;
  background-color: #f5f5f5;
}

.category-tabs-styled .category-tab {
  padding: 12px 30px;
  font-size: 16px;
  cursor: pointer;
  text-align: center;
  min-width: 100px;
  transition: all 0.3s ease;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none !important;
  margin: 0;
  text-decoration: none;
  box-shadow: none;
}

.category-tabs-styled .category-tab:first-child {
  border-top-left-radius: 40px;
  border-bottom-left-radius: 40px;
}

.category-tabs-styled .category-tab:last-child {
  border-top-right-radius: 40px;
  border-bottom-right-radius: 40px;
}

/* Default state */
.category-tabs-styled .category-tab:not(.active) {
  background-color: #f5f5f5;
  color: #333;
}

/* Active states for specific tabs */
.category-tabs-styled .category-tab.active {
  background-color: #333 !important;
  color: white !important;
  border: none !important;
}

/* Ensure Typography tab gets black background when active */
.category-tabs-styled .category-tab:nth-child(2).active {
  background-color: #333 !important;
  color: white !important;
}

/* Hover effects */
.category-tabs-styled .category-tab:hover:not(.active) {
  background-color: #e8e8e8;
}

/* Container for tabs */
.category-tabs.category-tabs-styled {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 30px;
}

/* Category Tabs Styling */
.category-tabs .main-tabs {
  display: flex;
  border-radius: 40px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border: 1px solid #e0e0e0;
  width: fit-content;
  margin: 0 auto;
}

.category-tabs .main-tabs .category-tab {
  padding: 12px 30px;
  min-width: 100px;
  font-size: 16px;
  cursor: pointer;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  background-color: #f5f5f5;
  color: #333;
  border: none;
}

/* Common styling for any active tab */
.category-tabs .main-tabs .category-tab.active {
  background-color: #333 !important;
  color: white !important;
  font-weight: 500;
}

/* Left tab (first child) */
.category-tabs .main-tabs .category-tab:first-child {
  border-radius: 40px 0 0 40px;
}

/* Right tab (last child) */
.category-tabs .main-tabs .category-tab:last-child {
  border-radius: 0 40px 40px 0;
}

/* Hover effect */
.category-tabs .main-tabs .category-tab:hover:not(.active) {
  background-color: #e9e9e9;
} 