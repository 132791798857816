.slick-slider {
    min-height: 300px;
    width: 100%;
}

.slick-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
}
  
.slick-slide img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    display: block;
    margin: 0 auto;
  }
  
  .slick-dots {
    bottom: 10px;
  }
  
  .slick-prev:before,
  .slick-next:before {
    color: #000;
  }

/* Carousel wrapper & placeholder */
.carousel-wrapper {
  position: relative;
  min-height: 300px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel-placeholder {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 300px;
  background-color: #f5f5f5;
  border-radius: 8px;
}

/* Loading spinner animation */
.loading-spinner {
  width: 40px;
  height: 40px;
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top-color: #000;
  animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* For better performance on slower devices */
@media (prefers-reduced-motion: reduce) {
  .loading-spinner {
    animation-duration: 1.5s;
  }
}