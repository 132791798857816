/* ImageGenerator.css */

@import url('https://fonts.googleapis.com/css2?family=Roboto&family=Open+Sans&family=Lato&family=Montserrat&display=swap');

/* General container styling */
.ai-image-generator {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #f7f7f7; /* Match the background color of WordPress site #ffffff #f7f7f7 */ 
    color: #333; /* Match the font color */
    /* font-family: 'Arial, sans-serif';  */
    min-height: 100vh; /* Ensures the container takes up the full viewport height */
    box-sizing: border-box;
    /* justify-content: center; Center content vertically */
    max-width: 100%; /* Ensure the parent container doesn't limit width */  
}

/* =================== Desktop layout improvements =================== */
@media (min-width: 1200px) {
  .ai-image-generator {
    padding: 20px 0%; /* Remove horizontal padding to maximize space */
  }
  
  /* Improve examples section on desktop */
  /* .examples-section {
    max-width: 1200px;
    margin: 40px auto;
  }
  
  .examples-section h2 {
    font-size: 2.5rem;
    margin-top: 80px;
  } */
  
  .category-tabs {
    gap: 30px;
    margin-bottom: 30px;
  }
  
  .category-tab {
    padding: 10px 25px;
    font-size: 1.1rem;
  }
  
  /* Main design container layout */
  .design-container {
    display: flex;
    flex-direction: row !important; /* Force horizontal layout on desktop */
    gap: 70px; /* Increased from 50px to 70px for more space between columns */
    width: 98%; /* Allow more space on the sides */
    max-width: 1900px; /* Increased max-width to allow more space */
    margin: 20px auto;
    justify-content: space-between; /* This spreads out the columns */
    padding-left: 0; /* Remove left padding */
  }
  
  .left-column1 {
    width: 20% !important; /* Slightly reduced width for left column */
    flex: 0 0 20% !important; 
    margin-left: -10px; /* Push further to the left with negative margin */
    padding-right: 35px; /* Increased from 20px to 35px */
  }
  
  .right-column1 {
    display: flex !important;
    flex-direction: row !important; /* Horizontal layout for right column */
    width: 80% !important;
    gap: 60px; /* Increased gap between prompt and preview columns */
    justify-content: space-between; /* Space between these columns */
    margin-left: 15px; /* Added new margin to create space from left column */
  }
  
  .prompt-col {
    flex: 1;
    max-width: 45%; /* Reduced to create more space for third column */
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 15px; /* Added padding for better spacing on desktop */
  }
  
  .preview-col {
    flex: 1;
    max-width: 45%; /* Reduced to allow for margin */
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: -10px; /* Push further to the right with negative margin */
    margin-left: auto; /* Push to the right */
  }
  
  /* Improve image display on desktop */
  .img-loading {
    margin: 20px 0;
  }
  
  .img-loading .image img {
    max-width: 100%;
    max-height: 400px;
    object-fit: contain;
  }
  
  /* T-shirt preview improvements for desktop */
  .tshirt-preview-container {
    width: 100%;
    max-width: 750px;
    /* margin: 20px auto 10px; */
    margin: 5px auto; /* Smaller margin on top/bottom */
    position: relative;
    background: transparent;
  }

  .tshirt-preview {
    width: 100%;
    position: relative;
    padding-bottom: 100%;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    margin: 0;
  }

  .tshirt-image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 40%;
    height: auto;
    object-fit: contain;
  }
  
  .color-picker, .size-picker {
    flex: 1;
    min-width: 40%;
    text-align: left;
    padding: 0 20px;
    margin-top: 20px;
  }
  
  .color-options, .size-options {
    display: flex;
    flex-wrap: nowrap;
    /* justify-content: flex-start; */
    justify-content: space-evenly; /* or space-between, space-around */
    align-items: center;
    gap: 8px;               /* small space between buttons */
    overflow-x: auto;       /* optional: let them scroll if too narrow *
    /* gap: 15px; */
  }
  
  /* Improve button layout on desktop */
  .button-row {
    justify-content: center;
    max-width: 500px;
    margin: 15px 0;
  }
  
  .button-row button {
    flex: 0 1 auto;
    min-width: 180px;
  }
  
  /* Improve saved images grid on desktop */
  .saved-images-grid {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }
  
  /* Improve carousel on desktop */
  /* .carousel-container {
    width: 100%;
    max-width: 600px !important; 
    margin: 0 auto;
  } */
  
  .slick-prev {
    left: -15px !important; /* Move previous button closer to edge */
  }
  
  .slick-next {
    right: -15px !important; /* Move next button closer to edge */
  }
  
  /* Improve prompt generator on desktop */
  .prompt-generator {
    padding: 18px;
    margin-bottom: 15px;
  }
  
  .tag-input, .tag-input-select, .tag-input-text, .tag-input-additional {
    margin-bottom: 10px;
  }
  
  textarea {
    min-height: 120px;
  }
}

/* =================== Logo Header =================== */
.logo-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
  padding: 10px 20px;
  background-color: #f9f9f9;
  border-bottom: 1px solid #ccc;
}

.logo-text {
  font-family: "Great Vibes", cursive;
  font-size: 2rem;
  font-weight: bold;
  margin: 0;
}

.logo-image {
  height: 35px;   /* Adjust as needed */
  width: auto;
  object-fit: contain;
margin: 0; /* Remove default margins */
border: none; /* Remove borders if present */
}
  
/* =================== Headers & Examples Section =================== */
.header {
    font-size: 2.2em;
    font-weight: bold;
    margin-bottom: 10px; /*30*/
    margin-top: 10px; 
    color: #0066cc; /* Match the header color */
    text-align: center;
}

.header-container {
    text-align: center;
    margin-bottom: 40px;
}

.header-title {
  font-size: 2.5rem;
  margin: 20px 0;
}

.header-subtitle {
  font-size: 1.8rem;
  margin: 10px 0 40px;
  color: #555;
}

.example-instruction {
  text-align: center;
  margin: 0 0 15px;
  font-size: 14px;
  color: #555;
  font-weight: 500;
  width: 100%;
  max-width: 500px;
  padding: 0 15px;
  box-sizing: border-box;
}

.examples-section {
  text-align: center;
  margin: 15px auto;
  max-width: 800px;
}

.examples-section h2 {
  font-size: 2.2rem;
  font-weight: 700;
  margin-top: 50px;
  margin-bottom: 30px;
  text-align: center;
}

.design-row {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;
}

/* Tabs for categories */
.category-tabs {
  display: flex !important;
  justify-content: center !important;
  margin-bottom: 30px !important;
  width: 100% !important;
}

.main-tabs {
  display: flex !important;
  border-radius: 40px !important;
  overflow: hidden !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1) !important;
  border: 1px solid #e0e0e0 !important;
  width: fit-content !important;
}

.category-tab {
  padding: 9px 18px !important;
  font-size: 16px !important;
  cursor: pointer !important;
  text-align: center !important;
  min-width: 100px !important;
  transition: all 0.3s ease !important;
  font-weight: 500 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  border: none !important;
  margin: 0 !important;
  text-decoration: none !important;
  background-color: transparent !important;
}



/* Saved images tab (right) */
.saved-images-tab {
  cursor: pointer;
  padding: 8px 16px;
  background-color: transparent;
  color: #3d7dd8;
  font-size: 0.9rem;
  font-weight: 500;
  transition: all 0.2s ease;
  border: none;
  text-decoration: none;
  margin: 0 auto;
  display: block;
  text-align: center;
}

.saved-images-tab:hover {
  color: #2c64b8;
  text-decoration: underline;
}

.saved-images-tab:after {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  bottom: 0;
  left: 50%;
  background-color: #3d7dd8;
  transition: all 0.3s ease;
}

.saved-images-tab:hover:after {
  width: 80%;
  left: 10%;
}

.saved-images-tab.active {
  background-color: transparent;
  color: #2c64b8;
  font-weight: 600;
  border: none;
}

.saved-images-tab.active:after {
  content: '';
  position: absolute;
  width: 80%;
  height: 2px;
  bottom: 0;
  left: 10%;
  background-color: #2c64b8;
}

/* Hide the old "View generated images" buttons that you replaced */
.view-generated-images-container {
  display: none !important;
  visibility: hidden;
  height: 0;
  width: 0;
  overflow: hidden;
  margin: 0;
  padding: 0;
}
.left-column1 .view-generated-images-container,
.carousel-container + .view-generated-images-container,
.preview-col .view-generated-images-container {
  display: none !important;
  visibility: hidden;
  height: 0;
  width: 0;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

/* .carousel-slide {
  min-height: 500px; 
} */

.carousel-slide img {
  width: 100%;
  height: auto;
  display: block;
}

    /* Improve carousel on desktop */
  .carousel-container {
    width: 100%;
    max-width: 1000px !important; 
    margin: 0 auto;
  }

/* =================== Main container for design steps =================== */
.design-container {
  display: flex;
  flex-direction: column; 
  gap: 20px;
  width: 100%;
  max-width: 1200px; 
  margin: 1px auto; 
  box-sizing: border-box;
}

/* Left Column for carousel/examples on mobile */
.left-column1 {
  width: 100%;  /* Mobile: full width */
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.left-column1 h3 {
  text-align: center;
  margin-bottom: 15px;
  font-size: 1.2rem;
  color: #333;
}

/* Right Column for the prompt & T-shirt preview on mobile */
.right-column1 {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}

/* =================== Prompt Column =================== */
.prompt-col {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
}

.prompt-display {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center; 
}

.prompt-display textarea {
  width: 100%;
  height: 130px;
  padding: 12px;
  margin: 8px 0 0 0;
  font-size: 1em;
  border-radius: 5px;
  border: 1px solid #ccc;
  background-color: #f0f0f0;
  color: #333;
  box-sizing: border-box;
  max-width: 800px;
}

/* Button row styling */
.button-row {
  display: flex;
  flex-direction: row;
  gap: 0.8rem;
  justify-content: center;
  width: 100%;
  margin: 5px auto;
}

.button-row button {
  width: auto !important;
  flex: 1;
  max-width: none;
  margin: 0;
}

/* Loading states for images */
.img-loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
  margin-top: 10px;
  width: 100%;
}

.img-loading img {
  max-width: 100%;
  width: 400px;
  margin: 0 auto;
}

.design-preview {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center; 
}

.design-preview .img-loading {
  width: 100%;
}

.design-preview .image img {
  width: 100%;
  max-width: 500px;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Carousel styling */
.clickable-slide {
  position: relative;
  cursor: pointer; 
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

.clickable-slide:hover {
  transform: translateY(-3px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.clickable-slide:active {
  transform: translateY(-1px);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.clickable-slide img {
  width: 100%;
  height: auto;
  display: block;
  border-radius: 8px;
}

/* Hover overlay for "Click/Tap" */
.click-hint-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5); 
  color: #fff;
  text-align: center;
  padding: 8px 0;
  font-size: 0.9rem;
  opacity: 0; 
  transition: opacity 0.3s ease;
  z-index: 999;
}

.clickable-slide:hover .click-hint-overlay {
  opacity: 1;
}

@media (pointer: coarse) {
  .click-hint-overlay {
    opacity: 0.8 !important; 
    padding: 6px 0;
    font-size: 0.85rem;
    background: rgba(0, 0, 0, 0.4);
  }
  
  .clickable-slide:active {
    opacity: 0.8;
  }
}

/* Prompt Generator panel */
.prompt-generator {
  max-width: 100%;
  margin: 0 auto;
  padding: 15px;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.tag-container {
max-width: 100%;
}

.tag-input,
.tag-input-select,
.tag-input-text, 
.tag-input-additional {
    width: 100%;
    box-sizing: border-box;
  margin-bottom: 8px;
}

.tag-input-select {
  margin-bottom: 8px;
}

.tag-input-select label,
.tag-input-additional label, 
.tag-input label {
  margin-top: 1px;
    font-weight: bold;
    font-family: 'Arial, sans-serif';
}

select,
input {
  width: 100%; 
  border: 1px solid #ccc; 
  border-radius: 4px; 
  font-size: 14px; 
    box-sizing: border-box;
  }

.prompt-display {
    width: 100%;
}

/* Buttons (Generate, Clear, Remove BG, etc.) */
.generate-btn,
/* .save-btn, */
/* .remove-bg-btn, */
.clear-btn,
.order-btn {
  width: 100%; 
  padding: 12px 20px;
  margin: 10px 0;
  background-color: #222;
  color: white;
  border: none;
  border-radius: 6px;
  font-size: 1rem;
  font-weight: 600;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease, transform 0.2s ease;
  cursor: pointer;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.generate-btn:hover,
/* .save-btn:hover, */
/* .remove-bg-btn:hover, */
.clear-btn:hover,
.order-btn:hover {
  background-color: #444;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.clear-btn {
  background-color: #666; 
  color: white;
}

.clear-btn:hover {
  background-color: #888;
}

/* Button row alignment */
.button-row {
  display: flex;
  flex-direction: row;
  gap: 0.8rem;
  justify-content: center;
  width: 100%;
}

.button-row button {
  width: auto !important;
  flex: 1;           
  max-width: none;   
  margin: 0;         
}

/* =================== T-shirt Preview (Simplified) =================== */
.tshirt-preview-container {
  width: 100%;
  max-width: 750px;
  /* margin: 20px auto 10px; */
  margin: 5px auto; /* reduce top/bottom margin */
  position: relative;
  background: transparent;
}

.tshirt-preview {
  width: 100%;
  position: relative;
  padding-bottom: 100%;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  margin: 0;
}

.tshirt-image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40%;
  height: auto;
  object-fit: contain;
}

/* Remove BG button in top-right or top-left corner, as you prefer */
.remove-bg-floating-btn {
  position: absolute;
  top: -20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
  background-color: rgba(45, 45, 45, 0.9);
  color: white;
  padding: 6px 10px;
  border: none;
  border-radius: 6px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  white-space: nowrap;
  transition: background-color 0.3s, transform 0.2s;
}

.remove-bg-floating-btn:hover {
  background-color: rgba(30, 30, 30, 0.9);
  transform: translateX(-50%) scale(1.05);
}

/* =================== Order Form =================== */
.order-form {
  margin: 0.3rem auto;
  max-width: 300px;
  background: #f9f9f9; 
  border-radius: 8px; 
  padding: 1.5rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.order-form h2 {
  text-align: center;
  margin-bottom: 1rem;
  font-family: sans-serif;
  font-weight: 600;
  color: #333;
}

.form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 0.9rem;
}

.form-group label {
  font-size: 0.9rem;
  font-weight: 500;
  margin-bottom: 0.2rem;
  color: #333;
}

.form-group input[type="text"], 
.form-group input[type="email"],
.form-group textarea {
  padding: 0.6rem;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 1rem;
  font-family: sans-serif;
  color: #333;
  outline: none;
}
  
.form-group input[type="text"]:focus, 
.form-group input[type="email"]:focus, 
.form-group textarea:focus {
  border-color: #333;
}

.form-group textarea {
  resize: vertical;
}

.order-btn-container {
  display: flex;
  justify-content: center;
  margin-top: 40px; 
  margin-bottom: 5px;
  width: 100%;
  text-align: center;
}   
  
/* Additional container or spacing if needed */
.button-group {
  display: flex;
  flex-direction: column;
  width: 100%; 
  gap: 10px; 
}

/* For feedback text area */
.feedback-option {
  margin-top: 15px;
  width: 100%;
}

.feedback-option textarea {
  width: 100%;
  padding: 12px;
  font-size: 1em;
  border: 1px solid #ccc;
  border-radius: 5px;
  resize: vertical;
}

.feedback-option p {
  margin-bottom: 10px;
  font-size: 1em;
}

/* Inputs styling */
input[type="text"],
textarea,
#description-textarea {
    font-size: 1em;
    padding: 12px;
  margin: 8px 0;
    width: 100% !important;
    box-sizing: border-box;
  border: 1px solid #cccccc;
  background-color: #fff;
  color: #333;
  border-radius: 5px;
}

/* =================== Back button =================== */
.back-btn {
  display: inline-block; 
  margin: 60px auto 5px; 
  padding: 10px 20px;
  background-color: #000;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  max-width: 200px; 
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); 
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.back-btn:hover {
  background-color: #333; 
  transform: translateY(-2px);
}

.back-btn:focus {
  outline: 2px solid #0056b3;
  outline-offset: 2px;
}

/* =================== Color & Size Options =================== */
.color-option {
  width: 35px; 
  height: 35px; 
  border-radius: 50%;
  border: 2px solid #ddd;
  cursor: pointer;
  transition: transform 0.2s ease, border-color 0.2s ease;
  position: relative; 
}

.color-option:hover {
  transform: scale(1.1);
}

.color-option.selected {
  border-color: #333; 
}

.color-option .checkmark {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  font-size: 1.2rem;
}

.select-tshirt-header {
  font-size: 1.1em;
  margin-bottom: 5px;
}
  
.select-size-header {
  margin-bottom: 5px; 
  font-size: 1.1em; 
}

.color-picker {
  margin: 0;
  padding: 0;
  background-color: transparent;
  text-align: center;
}
  
.size-picker {
  flex: 1;
  min-width: 40%;
  text-align: center;
  padding: 0 20px;
  margin-top: 0px; 
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* .size-options {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 15px;
} */

.color-options {
  display: flex;
  flex-wrap: nowrap; 
  justify-content: center; 
  gap: 10px; 
  margin-bottom: 5px; 
  overflow-x: auto; 
}
  
.size-option {
  padding: 8px 16px;
  border: 1px solid #ddd;
  border-radius: 5px;
  cursor: pointer;
  background-color: white;
  font-size: 13px; 
  transition: background-color 0.3s, border-color 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
  
.size-option:hover {
  background-color: #f0f0f0;
  border-color: #ccc;
}

.size-option.selected {
  background-color: #007bff;
  color: white;
  border-color: #007bff;
}
  
/* Container that holds color & size pickers */
.select-color-container {
  position: relative;
  display: flex;
  flex-direction: column; 
  gap: 20px;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding: 0;
  z-index: 1;
}

/* Loading overlays for removing background or generating image */
.bg-removal-loading,
.img-generation-loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
    display: flex;
      flex-direction: column;
    justify-content: center;
      align-items: center;
  z-index: 20;
  border-radius: 5px;
}

.bg-removal-loading-text,
.img-generation-loading-text {
  color: white;
  font-size: 16px;
  margin-bottom: 10px;
  font-weight: bold;
}

.bg-removal-loading-bar,
.img-generation-loading-bar {
  width: 80%;
  max-width: 250px;
  height: 8px;
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 4px;
  overflow: hidden;
}

.bg-removal-loading-bar-inner,
.img-generation-loading-bar-inner {
  height: 100%;
  width: 5%;
  background-color: #DE1B89;
  border-radius: 4px;
  animation: loading-animation 8s linear forwards;
  transform-origin: left center;
}

@keyframes loading-animation {
  0% {
    width: 5%;
  }
  100% {
    width: 95%;
  }
}

/* =================== Saved Images Panel =================== */
.saved-images-button-container {
  width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  margin: -20px auto 20px;
  /* padding: 0px 0px; */
  text-align: center;
  position: relative;
  z-index: 1;
}

.saved-images-button {
  background-color: transparent;
  color: #3d7dd8;
  border: none;
  padding: 0;
  margin: 0;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.3s ease;
  text-decoration: none;
  display: inline-block;
  line-height: 1;
}

.saved-images-button:hover {
  color: #2c64b8;
  text-decoration: underline;
}

.saved-images-panel {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  max-width: 1000px;
  max-height: 80vh;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  padding: 20px;
  z-index: 1000;
  overflow-y: auto;
  box-sizing: border-box;
}

.saved-images-panel h3 {
  text-align: center;
  margin-bottom: 10px;
  color: #333;
}

.saved-images-panel .note {
    text-align: center;
  margin-bottom: 20px;
  color: #444; 
  font-style: italic;
  font-size: 14px;
}

.saved-images-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 0 10px;
}

.saved-image-item {
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  transition: transform 0.2s;
  cursor: pointer;
}

.saved-image-item:hover {
  transform: scale(1.03);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.saved-image-item img {
      width: 100%;
  height: 200px;
  object-fit: cover;
}

.saved-image-prompt {
  padding: 10px;
  font-size: 15px; 
  color: #444; 
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.saved-image-date {
  padding: 0 10px 10px;
  font-size: 12px;
  color: #777;
}

/* Buttons container for Clear Images and Close */
.saved-images-buttons {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-top: 20px;
}

.close-btn {
  width: 130px; 
  padding: 10px 16px;
  background-color: #C74A4A;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
  font-weight: 500;
}

.close-btn:hover {
  background-color: #B43A3A;
}

.clear-all-btn {
  width: 130px; 
  padding: 10px 16px;
  background-color: #AA5C00;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
  font-weight: 500;
}

.clear-all-btn:hover {
  background-color: #964F00;
}

/* Overlay for the saved images panel */
.saved-images-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.button-row {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.save-btn, .remove-bg-btn{
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s;
}

.save-btn {
  background-color: #4CAF50;
  color: white;
}

.save-btn:hover {
  background-color: #45a049;
}

.remove-bg-btn {
  background-color: #2196F3;
  color: white;
  padding: 10px 20px;
  border: none;
    border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s;
  min-width: 180px;
}

.remove-bg-btn:hover {
  background-color: #0b7dda;
}

.view-saved-btn {
  display: inline-block;
  padding: 6px 12px;
  font-size: 14px;
  font-weight: 500;
  color: #0b8af9;
  background-color: #f0f8ff; /* light background color */
  border: 2px solid #54abf8;
  border-radius: 6px; /* smoother, uniform rounded corners */
  cursor: pointer;
  text-align: center;
  text-decoration: none; /* remove underline */
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.view-saved-btn:hover {
  background-color: 7abbf3;
  border-color:0b8af9;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.view-saved-btn.active {
  background-color: #0b8af9; 
  color: #222;
  border-color: 7abbf3;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  font-weight: 600; 
}


.save-btn:disabled, .remove-bg-btn:disabled {
  background-color: #cccccc;
  color: #666666;
  cursor: not-allowed;
}

.order-btn {
  width: 100%;
  padding: 12px 20px;
  margin: 0 auto;
  background-color: #222;
  color: white;
  border: none;
  border-radius: 6px;
  font-size: 1rem;
  font-weight: 600;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease, transform 0.2s ease;
  cursor: pointer;
  max-width: 220px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
}

.order-btn:hover {
  background-color: #444;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
    transform: translateY(-2px);
  }

.order-button-wrapper {
  width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  margin-top: 10px; 
  text-align: center;
}

.order-btn-container {
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
  text-align: center;
}

/* Carousel titles (optional) */
.carousel-title {
  text-align: center;
  margin-bottom: 20px;
  margin-top: 5px;
  font-size: 1.2rem;
  font-weight: 600;
  color: #333;
    width: 100%;
  left: 0;
  right: 0;
  position: relative;
  margin: 0 0 5px !important;  /* remove top margin, keep 5px bottom */
  padding: 0;
}

.carousel-subtitle {
  text-align: center;
  margin-bottom: 20px;
  font-size: 1rem;
  font-style: italic;
  color: #666;
  width: 100%;
}

/* Make sure you have enough specificity or use !important */
/* .slick-list {
  overflow: visible !important;
  height: auto !important;
} */
/* .slick-track {
  height: auto !important;
} */


/* @media (min-width: 1200px) {
  .carousel-title {
    font-size: 1.4rem;
    margin-top: 10px;
    margin-bottom: 20px;
  }
  .carousel-subtitle {
    margin-bottom: 25px;
  }
} */

/* @media (max-width: 600px) {
  .color-option {
    width: 30px; 
    height: 30px;
  }
  
.size-option {
    padding: 6px 12px;
    font-size: 12px;
  }

  .select-tshirt-header,
  .select-size-header {
    font-size: 1.2em;
  }
} */

/* Small mobile devices */
/* @media (max-width: 480px) {
  .tshirt-preview-container {
    max-width: 450px;
    margin: 10px auto;
  }

.tshirt-preview {
    padding-bottom: 100%;
}

.tshirt-image {
    width: 40%;
  }
} */

/* =================== Additional small media queries =================== */
@media (max-width: 768px) {
  .saved-images-panel {
    width: 95%;
    padding: 15px;
    /* max-height: 95vh; */
  }

  .saved-images-grid {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 15px;
    padding: 0 5px;
  }

  .saved-image-item img {
    height: 150px;
  }

  .saved-image-prompt {
    font-size: 14px;
    padding: 8px;
  }

  .saved-image-date {
    font-size: 11px;
    padding: 0 8px 8px;
  }
}

@media (max-width: 480px) {
  .saved-images-panel {
    /* top: 470px; */
    width: 80%;
    /* padding: 10px; */
  }

  .saved-images-grid {
    grid-template-columns: 1fr;
    gap: 15px;
  }

  .saved-image-item {
    max-width: 100%;
  }

  .saved-image-item img {
    height: auto;
    max-height: 300px;
  }
}



/* Samsung Galaxy S8+(360), Galaxy Z Fold 5(344) */
@media (max-width: 360px) {

  .tshirt-preview-container {
    width: 100% !important;
    max-width: none !important;
    margin: 5px auto !important; /* or 0 auto if you like */
  }

  /* Keep a square or near-square aspect ratio */
  .tshirt-preview {
    padding-bottom: 100% !important; /* or 90% if you prefer slightly less tall */
  }

  /* Make the T-shirt graphic itself fill more of the container */
  .tshirt-image {
    width: 40% !important; /* or 80%, 90%, etc. */
  }

  .remove-bg-floating-btn {
    padding: 6px 10px;
    font-size: 12px;
    top: -20px !important; 
  }
  .generate-btn,
  .clear-btn {
    font-size: 0.85rem;   /* slightly smaller text */
    padding: 10px 14px;    /* less padding around the text */
    height: auto;         /* remove fixed height if desired */
  }

  .order-btn-container {
  display: flex;
  justify-content: center;
  }

  .order-btn {
    width: 100%;        
    max-width: 300px;   
    margin: 0 auto; 
    font-size: 1rem;    
    padding: 12px 20px;
  }

  .size-options {
    display: flex !important;
    flex-wrap: nowrap !important;  /* No wrapping, stay in one row */
    justify-content: space-between; /* Distribute buttons horizontally */
    align-items: center;           /* Vertically center them */
    gap: 16px;
  }

  .size-option {
    flex: 1 0 auto;   /* Let them shrink if needed */
    max-width: 38px;  /* Or some fixed width so they don't grow too large */
    margin: 0 0px;    /* Tiny horizontal gap between buttons */
  }


  .carousel-container {
    max-width: 90% !important;
    margin: 0 auto 20px;
  }

  /* .slick-list {
    overflow: visible !important; 
    height: auto !important;
  }
  .slick-track {
    height: auto !important;
  } */

  .slick-slider {
    max-width: 300px; 
    margin: 0 auto;   
    padding-bottom: 40px; /* give space for dots */
    position: relative;   /* so the dots can appear inside */
  }
}

/*  iPhone SE(375)  */
@media (max-width: 375px) {
  /* .slick-list {
    overflow: visible !important; 
    height: auto !important;
  }
  .slick-track {
    height: auto !important;
  } */

  /* .slick-slider {
    padding-bottom: 140px; 
    position: relative;   
  } */
 
  
  .tshirt-preview-container {
    /* max-width: 450px; */
    width: 100% !important;
    max-width: none !important;
    margin: 5px auto !important; /* or 0 auto if you like */
  }

  .tshirt-preview {
    padding-bottom: 100%;
  }

  .tshirt-image {
    width: 40%;
  }

  .remove-bg-floating-btn {
    padding: 5px 10px;
    font-size: 11px;
    top: -20px !important; 
  }

  .generate-btn,
  .clear-btn, .order-btn {
    font-size: 0.85rem;   /* slightly smaller text */
    padding: 10px 14px;    /* less padding around the text */
    height: auto;         /* remove fixed height if desired */
  }

  .size-options {
    display: flex !important;
    flex-wrap: nowrap !important;  /* No wrapping, stay in one row */
    justify-content: space-between; /* Distribute buttons horizontally */
    align-items: center;           /* Vertically center them */
    gap: 16px;
  }

  .size-option {
    flex: 1 0 auto;   /* Let them shrink if needed */
    max-width: 38px;  /* Or some fixed width so they don't grow too large */
    margin: 0 0px;    /* Tiny horizontal gap between buttons */
  }

  
  .carousel-container {
    max-width: 90% !important;
    margin: 0 auto 20px;
  }

  /* .slick-list {
    overflow: visible !important; 
    height: auto !important;
  }
  .slick-track {
    height: auto !important;
  } */

  .slick-slider {
    max-width: 300px; 
    margin: 0 auto;   
    padding-bottom: 20px; /* give space for dots */
    position: relative;   /* so the dots can appear inside */
  }

}

/* iPhones XR(414), Pixel 7(412), Samsung Galaxy A51/71(412) */
/* iPhone 12 Pro(390), Samsung Galaxy S20 Ultra(420) */
@media (max-width: 414px) {

  .size-options {
    display: flex !important;
    flex-wrap: nowrap !important;  /* No wrapping, stay in one row */
    justify-content: space-between; /* Distribute buttons horizontally */
    align-items: center;           /* Vertically center them */
    gap: 16px;
  }

  .size-option {
    flex: 1 0 auto;   /* Let them shrink if needed */
    max-width: 38px;  /* Or some fixed width so they don't grow too large */
    margin: 0 0px;    /* Tiny horizontal gap between buttons */
  }

  .click-hint-overlay {
    opacity: 0.9 !important;
    background: rgba(0, 0, 0, 0.6);
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 8px 0;
    font-size: 0.9rem;
    text-align: center;
  }

  .tshirt-preview-container {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 10px;
    transform: none; /* remove scale if you like */
  }

  .tshirt-preview {
    max-height: 360px;
    overflow: visible;
    margin-bottom: 20px;
  }

  .color-picker {
    margin-top: -10px;
  }

  .ai-image-generator {
    overflow-x: hidden;
  }

  .select-color-container {
    overflow: visible;
    margin-bottom: 50px; 
  }

  /* .carousel-slide img {
    display: block; 
    width: 100%; 
    height: auto;
  } */
  /* .clickable-slide {
    overflow: visible; 
  } */

  .carousel-container {
    max-width: 90% !important;
    /* margin: 0 auto 20px; */
    /* max-width: 300px;  */
    /* margin: 0 auto; */
  }

  /* .slick-list {
    overflow: visible !important; 
    height: auto !important;
  }
  .slick-track {
    height: auto !important;
  } */

  .slick-slider {
    max-width: 280px; 
    margin: 0 auto;   
    padding-bottom: 20px; /* give space for dots */
    position: relative;   /* so the dots can appear inside */
  }
}


/* iPhone 14 Pro Max(430) */
@media (max-width: 430px) {
  
  .size-options {
    display: flex !important;
    flex-wrap: nowrap !important;  /* No wrapping, stay in one row */
    justify-content: space-between; /* Distribute buttons horizontally */
    align-items: center;           /* Vertically center them */
    gap: 16px;
  }

  .size-option {
    flex: 1 0 auto;   /* Let them shrink if needed */
    max-width: 38px;  /* Or some fixed width so they don't grow too large */
    margin: 0 0px;    /* Tiny horizontal gap between buttons */
  }

  .click-hint-overlay {
    opacity: 0.9 !important;
    background: rgba(0, 0, 0, 0.6);
    position: absolute;
    bottom: 0;
  width: 100%;
    padding: 8px 0;
    font-size: 0.9rem;
    text-align: center;
  }

  .tshirt-preview-container {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 10px;
    transform: none; /* remove scale if you like */
  }

  .tshirt-preview {
    max-height: 360px;
    overflow: visible;
    margin-bottom: 20px;
  }

  .color-picker {
    margin-top: -10px;
  }

  .ai-image-generator {
    overflow-x: hidden;
  }

  .select-color-container {
    overflow: visible;
    margin-bottom: 50px; 
  }

  .carousel-container {
    max-width: 90% !important;
    margin: 0 auto 0px;
  }

  /* .slick-list {
    overflow: visible !important; 
    height: auto !important;
  }

  .slick-track {
    height: auto !important;
  } */

  .slick-slider {
    max-width: 300px; 
    margin: 0 auto;   
    padding-bottom: 30px; /* give space for dots */
    position: relative;   /* so the dots can appear inside */
  }
}

