.payment-failure-container {
    max-width: 600px;
    margin: 100px auto 0;
    text-align: center;
    background: #f0f0f0; 
    padding: 2rem;
    border-radius: 8px;
    font-family: sans-serif;
  }
  
  .payment-failure-container h1 {
    color: #e74c3c;
    margin-bottom: 1rem;
  }
  
  .payment-failure-container p {
    color: #333;
    font-size: 1.1rem;
    margin-bottom: 2rem;
  }
  
  .failure-actions a {
    display: inline-block;
    text-decoration: none;
    background: #e74c3c;
    color: #fff;
    padding: 0.75rem 1.5rem;
    border-radius: 4px;
    transition: background 0.3s ease;
  }
  
  .failure-actions a:hover {
    background: #c0392b;
  }